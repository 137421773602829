<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">自分の操作履歴</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <histories-list
          :historiesListLoading="loading"
          :histories="histories"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import HistoriesList from '@/components/ListComponents/Histories'
import { enumData } from '@/services/enum'

export default {
  components: {
    HistoriesList,
  },
  data() {
    return {
      loading: true,
      histories: [],
    }
  },
  created() {
    const result = Vue.prototype.$api.send('get', 'histories')
    result.then(response => {
      this.histories = response.map((item) => {
        const model = enumData.historyModel.find(target => target.value === item.target)
        item.target = model.label
        item.targetTag = model.tag
        return item
      })
      this.loading = false
      console.log(response)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 操作履歴の取得に失敗しました。',
        })
        this.loading = false
      })
  },
}
</script>

<style>
.list-name {
  font-size: 1.5rem;
}
</style>
