<template>
  <section class="card">
    <div class="card-header">
      <h2>操作履歴一覧</h2>
    </div>
    <div class="card-body">
      <div v-if="historiesListLoading" class="text-center">
        <a-spin tip="Loading..." />
      </div>
      <div v-if="!historiesListLoading">
        <span class="mr-5">フィルター</span>
        <a-select
          mode="multiple"
          placeholder="権限"
          :value="selectedTargets"
          style="width: 40%"
          @change="handleChange"
        >
          <a-select-option v-for="item in filteredOptions" :key="item.label" :value="item.label">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <a-table
          :columns="columns"
          :data-source="filteredHistories"
          rowKey="id"
        >
          <span slot="created_at" slot-scope="created_at">{{ created_at | moment }}</span>
          <span slot="target" slot-scope="target, record"><a-tag :color="record.targetTag">{{ target }}</a-tag></span>
          <span slot="action" slot-scope="action">
            <i :class="action == '作成' ? 'lnr lnr-database' : (action == '更新' ? 'lnr lnr-redo' : 'lnr lnr-trash')" />
            {{ action }}
          </span>
        </a-table>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import { enumData } from '@/services/enum'

const OPTIONS = enumData.historyModel

export default {
  data() {
    return {
      columns: [
        { title: '日時', dataIndex: 'created_at', scopedSlots: { customRender: 'created_at' } },
        { title: '対象', dataIndex: 'target', scopedSlots: { customRender: 'target' }, align: 'center' },
        { title: '名前', dataIndex: 'name' },
        { title: '操作', dataIndex: 'action', scopedSlots: { customRender: 'action' } },
        { title: '操作者', dataIndex: 'user_by' },
      ],
      selectedTargets: [],
    }
  },
  props: [
    'historiesListLoading',
    'histories',
  ],
  computed: {
    filteredOptions() {
      return OPTIONS.filter(o => !this.selectedTargets.includes(o))
    },
    filteredHistories() {
      if (!this.selectedTargets.length) return this.histories
      else return this.histories.filter(u => this.selectedTargets.includes(u.target))
    },
  },
  created() {
    const _this = this
    if (this.$route.meta.userHidden) {
      this.columns.some(function(v, i) {
        if (v.title === '操作者') _this.columns.splice(i, 1)
      })
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY/MM/DD HH:mm')
    },
  },
  methods: {
    handleChange(selectedTargets) {
      this.selectedTargets = selectedTargets
    },
  },
}
</script>
